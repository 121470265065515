<template>
  <v-app>
    <b-card no-body class="card-custom card-stretch gutter-b">
      <b-card-header class="border-0 py-5">
        <h3
          class="card-title align-items-start flex-column"
          style="margin: auto 0"
        >
          <span class="card-label font-weight-bolder text-dark">
            {{ $t("pages.reservations_enter_classroom") }}
          </span>
        </h3>
        <div class="card-toolbar">
          <base-button
            @click="actionHandler('store', null)"
            color="primary"
            class="font-weight-bolder font-size-sm"
            _class="mr-2"
            description="اضافة حجز دخول لقاعة"
          >
            <i class="flaticon2-plus icon-1x" style="color: #fff"></i>
          </base-button>
        </div>
      </b-card-header>
      <v-divider class="m-0"></v-divider>
      <b-card-body>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="10" lg="10" sm="10">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" lg="2" sm="2" class="text-center">
                <button
                  @click="index({ type: 1 })"
                  class="btn btn-secondary mt-3"
                >
                  <i class="flaticon2-reload icon-1x"></i>
                </button>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="reservations"
            :search="search"
            :loading="loadTable"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.account.avatar="{ item }">
              <v-img
                contain
                max-height="100"
                max-width="100"
                :src="item.account.avatar"
                style="margin: 5px"
              ></v-img>
            </template>
          </v-data-table>
        </v-card>
      </b-card-body>
    </b-card>
    <classroomDialog
      :readonly="readonly"
      :edit="edit"
      @submit="check"
      @close="dialog = false"
      :loadDialog="loadDialog"
      :dialog="dialog"
      ref="dialog"
    ></classroomDialog>
    <StoreDialog
      :readonly="readonly"
      :edit="edit"
      @submit="save"
      @close="dialog1 = false"
      :loadDialog="loadDialog1"
      :dialog="dialog1"
      ref="dialog"
    ></StoreDialog>
  </v-app>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import classroomDialog from "./components/classroom.vue";
import StoreDialog from "./components/store.vue";
import BaseButton from "../../components/btn/base-button.vue";
export default {
  components: { classroomDialog, BaseButton, StoreDialog },
  data() {
    return {
      search: "",
      headers: [
        { text: '#', value: "id" },
        { text: this.$t("attributes.avatar"), value: "account.avatar" },
        { text: this.$t("attributes.name"), value: "account.name" },
        {
          text: "الحالة",
          value: "status_readable",
        },
        {
          text: "القاعة",
          value: "classroom.name",
        },
        {
          text: "التاريخ",
          value: "date",
        },
        {
          text: "الوقت",
          value: "time",
        },
      ],
      loadTable: true,
      dialog: false,
      dialog1: false,
      readonly: false,
      edit: false,
      loadDialog: false,
      loadDialog1:false
    };
  },
  computed: {
    ...mapGetters("Reservations", [
      "reservations",
      "reservation",
      "classrooms",
      "activities",
      "activities_times",
      "birthday_data",
      "form",
    ]),
  },
  watch: {},
  mounted() {
    console.log(moment());
    this.index({ type: 1 }).then(() => {
      this.loadTable = false;
    });
    this.clear({type:1});
  },
  methods: {
    submit() {
      this.loadDialog = true;
      const type = "store";
      this[type](this.form)
        .then(() => {
          this.dialog = false;
        })
        .catch(({ response }) => {
          // console.log())
          console.log(response);
          if (response.status == 422) {
            this.$refs.dialog.$refs.form.$refs.observer.setErrors(
              response.data.errors
            );
          } else {
            this.send_error(response.data.message);
          }
          this.loadDialog = false;
        });
    },
    check() {
      this.loadDialog = true;
      this.store({
        account_id: this.form.account_id,
        classroom_id: this.form.classroom_id,
        date: this.form.date,
        time: this.form.time,
        reservation_type: this.form.reservation_type,
        store: false,
      }).then(() => {
        this.loadDialog = true;
        this.dialog = false;
        this.dialog1 = true;
        this.send_message(
          "يمكنك تثبيت الحجز في الوقت المختار يرجى ادخال كلمة مرور الحساب لتأكيد الحجز"
        );
      }).catch(err=>{
        console.log(err.response.data);
        this.send_error(err.response.data.message);
        this.loadDialog = false;
      });
    },
    save() {
      this.loadDialog1 = true;
      this.store({
        account_id: this.form.account_id,
        classroom_id: this.form.classroom_id,
        date: this.form.date,
        time: this.form.time,
        reservation_type: this.form.reservation_type,
        store: 1,
        password:this.form.password
      }).then((res) => {
        this.loadDialog1 = false;
        this.dialog = false;
        this.dialog1 = false;
        this.send_message(res.data.message);
      }).catch(err=>{
        
        this.send_error(err.response.data.message);
        this.loadDialog1 = false;
      });
    },
    actionHandler(type, item) {
      this.loadDialog = false;
      this.dialog = true;
      if (item) {
        this.loadDialog = true;
        Promise.all([this.show(item.id)]).then(() => {
          this.loadDialog = false;
        });
      }
    },
    ...mapActions("Reservations", [
      "index",
      "store",
      "update",
      "show",
      "clear",
      "delete",
    ]),
    deleteModal(row) {
      this.$bvModal
        .msgBoxConfirm(
          this.$createElement("div", {
            domProps: {
              innerHTML: `هل انت متأكد من حذف الحساب  <h5 class="d-inline">${row.name}</h5> ?`,
            },
          }),
          {
            title: "تأكيد الحذف",
            okTitle: "تاكيد",
            cancelTitle: "الغاء",
          }
        )
        .then((value) => {
          if (value) {
            this.loadTable = true;
            this.delete(row.id).then(() => {
              this.loadTable = false;
            });
          }
        });
    },
  },
};
</script>

<style>
</style>